import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaSearch } from 'react-icons/fa';

const MatchTable = ({ setShowTable, withMatchData }) => {
  const [rows, setRows] = useState(10);
  const [rowsPerPageOptions] = useState([5, 10, 25, 50, 100]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [visibleColumns, setVisibleColumns] = useState([
    'Uploaded_fullname',
    'Uploaded_mun_name',
    'RSBSA_sys_generated_rsbsa_no',
    'RSBSA_fullname',
    'RSBSA_mun_name',
    'percentage',
    'remarks',
  ]);
  
  const dataTableRef = useRef(null);

  const encodedCount = withMatchData.filter(item => item.remarks === 'ENCODED').length;
  const verificationCount = withMatchData.filter(item => item.remarks === 'FOR VERIFICATION').length;
  const notEncodedCount = withMatchData.filter(item => item.remarks === 'NOT ENCODED').length;

  useEffect(() => {
    setVisibleColumns(['Uploaded_fullname', 'Uploaded_mun_name', 'RSBSA_sys_generated_rsbsa_no', 'RSBSA_fullname', 'RSBSA_mun_name', 'percentage', 'remarks']);
  }, [withMatchData]);

  const onColumnToggle = (event) => {
    const uploadedColumns = event.value.filter((column) => column.startsWith('Uploaded_'));
    const rsbsaColumns = event.value.filter((column) => column.startsWith('RSBSA_'));
    const additionalColumns = ['percentage', 'remarks'];
  
    setVisibleColumns([...uploadedColumns, ...rsbsaColumns, ...additionalColumns]);
  };
  
  const onGlobalFilterChange = (event) => {
    setGlobalFilter(event.target.value);
  };

  const RowNumber = ({ rowIndex }) => (
    <div>{rowIndex + 1}</div>
  );

  const exportCSV = () => {
    if (dataTableRef.current) {
      const csvVisibleColumnCount = visibleColumns.filter(columnName => columnName.startsWith('Uploaded_')).length;
      const uploadedColumnsReversed = [...visibleColumns].reverse();
      const lastUploadedIndex = visibleColumns.length - 1 - uploadedColumnsReversed.indexOf(uploadedColumnsReversed.find(column => column.startsWith('Uploaded_')));

      const currentDate = new Date();
      const formattedDateTime = currentDate.toISOString().replace(/:/g, '-').replace(/\..+/, '');
  
      const additionalHeader = `Uploaded Data${','.repeat(csvVisibleColumnCount + 4)}RSBSA Data`;
      const header = `${visibleColumns.slice(0, lastUploadedIndex + 1)},${''},${''},${''},${''},${visibleColumns.slice(lastUploadedIndex + 1)}`;
  
      const combinedData = [
        additionalHeader,
        header,
        ...prefixedData.map((rowData) => {
          const rowValues = visibleColumns.map((columnName) => {
            const field = rowData[columnName];
            return field !== undefined ? field : '';
          });
          rowValues.splice(lastUploadedIndex + 1, 0, '', '', '', '');
          return rowValues.join(',');
        }),
      ].join('\n');  
  
      const blob = new Blob([combinedData], { type: 'text/csv;charset=utf-8;' });
  
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `CrossMatchedData_${formattedDateTime}.csv`;
      link.click();
    }
  };

  const determineRemarks = (rowData) => {
    const remarksValue = rowData.remarks;

    const setBadgeColor = () => {
      if (remarksValue === 'ENCODED') {
        return 'success';
      } else if (remarksValue === 'FOR VERIFICATION') {
        return 'warning';
      } else if (remarksValue === 'SKIP') {
        return 'info';
      } else if (remarksValue === 'NOT ENCODED') {
        return 'danger';
      }
      return 'secondary'; 
    };
    return (
      <Badge bg={setBadgeColor()}>{remarksValue}</Badge>
    );
  };

  const concatName = (data) => {
    return data.map((item) => {
      return {
        ...item,
        csvData: {
          ...item.csvData,
          fullname: `${item.csvData.lastname} ${item.csvData.firstname} ${item.csvData.middle_name}`,
        },
        dbData: {
          ...item.dbData,
          fullname: item.remarks !== 'NOT ENCODED' ? `${item.dbData.lastname} ${item.dbData.firstname} ${item.dbData.middle_name}` : '',
        },
      };
    });
  };  
  
  const prefixedData = concatName(withMatchData).map((item) => {
    const prefixedCsvData = Object.fromEntries(
      Object.entries(item.csvData).map(([key, value]) => [`Uploaded_${key}`, value])
    );
  
    const prefixedDbData = Object.fromEntries(
      Object.entries(item.dbData).map(([key, value]) => [`RSBSA_${key}`, value])
    );
  
    const additionalData = {
      percentage: item.percentage ? `${parseFloat(item.percentage).toFixed(2)}%` : '',
      remarks: item.remarks,
    };
  
    return { ...prefixedCsvData, ...prefixedDbData, ...additionalData };
  });

  const filteredData = prefixedData.filter((rowData) => {
    return Object.values(rowData).some((value) =>
      String(value).toLowerCase().includes(globalFilter.toLowerCase())
    );
  }); 

  const allKeys = Object.keys(prefixedData[0] || {});

  const multiSelectOptions = allKeys.map((columnName) => ({
    label: columnName,
    value: columnName,
  }));

  const multiSelectHeader = (
    <div>
    <MultiSelect
      value={visibleColumns}
      options={multiSelectOptions}
      onChange={onColumnToggle}
      placeholder="Select Columns"
      display="chip"
    />
    </div>
  );

  const columns = visibleColumns.map(key => (
    <Column key={key} field={key} header={key} footer={key}
    body={(rowData) => (
      <div>
        {key === 'remarks' ? determineRemarks(rowData) : rowData[key]}
      </div>
    )}
    sortable
    />
  ));

  return (
    <div className="vh-100">
        {withMatchData.length > 0 ? (
          <>
            <Row className='g-3 mx-1'>
              <Col md="auto"><Card className='counter-card' title={<>Encoded <Badge bg="success">Encoded</Badge></>}> {encodedCount} </Card></Col>
              <Col md="auto"><Card className='counter-card' title={<>For Verification <Badge bg="warning">For Verification</Badge></>}> {verificationCount} </Card></Col>
              <Col md="auto"><Card className='counter-card' title={<>Not Encoded <Badge bg="danger">Not Encoded</Badge></>}> {notEncodedCount} </Card></Col>
            </Row>
            <div id='home-container' className='home-container border mx-2 mt-2 pt-2 p-1 rounded bg-white' style={{boxShadow:'none'}}>
              <div className='d-flex justify-content-between'>
                <p className='text-start ms-3 mt-3'>
                  <Button
                    tooltip="Only displayed columns will be exported. Toggle all the columns you want to export."
                    icon="pi pi-file-o"
                    label="Export Data"
                    onClick={exportCSV}
                    size="small"
                    raised
                  />
                  <Button className='ms-2' label="Back" onClick={() => {setShowTable();}} size="small" raised></Button>
                </p>
                <p className="text-end mt-3">
                  <input
                    type="text"
                    value={globalFilter}
                    onChange={onGlobalFilterChange}
                    placeholder="Search..."
                    style={{
                      padding: '12px 40px 12px 15px',
                      fontSize: '16px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      boxSizing: 'border-box',
                      outline: 'none',
                      transition: 'border-color 0.2s',
                    }}
                    onFocus={(e) => (e.target.style.borderColor = '#89CFF0')}
                    onBlur={(e) => (e.target.style.borderColor = '#ccc')}
                  />
                  <span style={{ position: 'relative', left: '-40px', bottom: '3px' }}>
                    <FaSearch color="#4682B4" size={18} />
                  </span>
                </p>
              </div>
              <DataTable 
                ref={dataTableRef}
                value={filteredData}
                header={multiSelectHeader}
                paginator
                rows={rows}
                rowsPerPageOptions={rowsPerPageOptions}
                className="p-datatable-striped"
                size={'small'}
                removableSort
                resizableColumns
                reorderableColumns
                columnResizeMode="expand">
                <Column
                  body={(rowData, column) => <RowNumber rowIndex={column.rowIndex} />}
                  header="#" footer='#'
                />
                {columns}
              </DataTable>
            </div>
          </>
          ) : (
          <p>No Matched Data.</p>
        )}
    </div>
  );
};

export default MatchTable;