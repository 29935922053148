import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";
import axios from 'axios';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { Toast } from 'primereact/toast';
import React, { useRef, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../Layouts/DefaultHeader';
import Footer from '../Layouts/Footer';
import ValidationSchema from '../Schemas/LoginValidationSchema';
import '../../css/App.css';
import '../../css/style.css';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const toastRef = useRef(null);

  const LOGIN_API = `${BASE_URL}/api/user/login`;

  const showToast = (severity, summary, detail) => {
    toastRef.current.show({ severity, summary, detail });
  };

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      navigate('/home', { replace: true });
    } else {
      navigate('/')
    }
  }, [navigate]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (values) => {
    try {
      await ValidationSchema.validate(values);
      const response = await axios.post(LOGIN_API, values, {withCredentials: true});
      if (response.data.statusCode === 200) {
          navigate('/home');
      } else if (response.data.statusCode === 500) {
          navigate('/500');
      } else {
          showToast('error', 'Error', response.data.message);
      }
    } catch (error) {
        navigate('/network-error');
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: ValidationSchema,
    onSubmit,
  });

  return (
    <div className="login-template">
      <Header />
      <div className="content">
        <Row>
          <Col sm={6} className='img-col text-center vh-100'>
            <img className='login-logo' src='/img/DA-Logo.png' alt='DA-logo'></img>
          </Col>
          <Col sm={6} className='login-column' >
          <div>
            <div className='login-container ms-5 p-5 rounded bg-white'>
              <form action='' onSubmit={handleSubmit}>
                <div className='form-header'>
                  <div className="row mb-4">
                    <div className="col-md-5">
                      <img
                        src="/img/DA-LOGO-1024x1024.png"
                        alt="Logo"
                        style={{ width: '150px', height: '150px' }}
                        className='form-logo'
                      />
                    </div>
                    <div className="col-md-7">
                      <br />
                      <h6 className="text-black">Registry System for Basic Sectors in Agriculture</h6>
                      <h3 className="text-black">RSBSA Crossmatch</h3>
                    </div>
                  </div>
                </div>
                <div className='mb-2'>
                  <label htmlFor='e-mail'>Email</label>
                  <input
                    id="e-mail"
                    type='email'
                    name='email'
                    placeholder='Enter email'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${errors.email && touched.email ? "input-error" : ""}`}
                  />
                  {errors.email && touched.email && <small className="text-danger">{errors.email}</small>}
                </div>
                <div>
                  <label htmlFor='pass-word'>Password</label>
                  <div className='input-with-icon'>
                    <input
                      id='pass-word'
                      type= {showPassword ? 'text' : 'password'}
                      name='password'
                      placeholder='Enter password'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-control ${errors.password && touched.password ? "input-error" : ""}`}
                    />
                    <span className="input-icon" onClick={handleTogglePassword}>
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                  {errors.password && touched.password && <small className="text-danger">{errors.password}</small>}
                </div>
                <div className='mb-2 d-flex justify-content-between align-items-center'>
                  <div>
                    <input type='checkbox' className='custom-control custom-checkbox' id='check' />
                    <label htmlFor='check' className='custom-input-label ms-2'>Remember Me</label>
                  </div>
                  <p className='text-end mt-2'>
                    <a href='/forgot-password'>Forgot Password?</a>
                  </p>
                </div>
                <div className='d-grid'>
                  <button disabled={isSubmitting} type='submit' className='btn btn-primary'>Log In</button>
                </div>
              </form>
              <Toast ref={toastRef} />
            </div>
          </div>
          </Col>
        </Row>
        <Footer />
      </div>
    </div>
  );
}

export default Login;
