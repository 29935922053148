import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const SessionExpiredModal = ({ show, onHide, handleLogout }) => {
  const handleClose = () => {
    onHide();
    handleLogout();
  };
  return (
    <Modal {...{ show, onHide }} size="sm" centered>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <h4>Session expired, please log in again.</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Okay</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionExpiredModal;
