import axios from 'axios';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import Header from '../Layouts/DefaultHeader';
import Footer from '../Layouts/Footer';
import ValidationSchema from '../Schemas/ForgotPasswordValidationSchema';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const toastRef = useRef(null);

    const FORGOT_PASSWORD_API = `${BASE_URL}/api/user/reset-password`;

    const showToast = (severity, summary, detail) => {
        toastRef.current.show({ severity, summary, detail });
    };

    const onSubmit = async (values) => {
        try {
            await ValidationSchema.validate(values);
            const response = await axios.post(FORGOT_PASSWORD_API, values, {withCredentials: true});
            if (response.data.statusCode === 200) {
                showToast('success', 'Success', response.data.message);
            } else if (response.data.statusCode === 500) {
                navigate('/500');
            } else {
                showToast('error', 'Error', response.data.message);
            }
        } catch (error) {
            navigate('/network-error');
        }
    };

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        } = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ValidationSchema,
        onSubmit,
        });

  return (
    <div>
        <Header />
        <div className="content">
            <div className='fp-container mx-auto vh-100'>
                <Card>
                    <Card.Header>Forgot Password</Card.Header>
                    <Card.Body>
                        <Card.Text>Please enter your email to reset your password.</Card.Text>
                        <form action=''>
                            <div className='fp-form mx-auto mt-5 mb-4'>
                                <input
                                    id="e-mail"
                                    type='email'
                                    name='email'
                                    placeholder='Enter email'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={`form-control ${errors.email && touched.email ? 'input-error' : ''}`}
                                />
                                {errors.email && touched.email && <small className="text-danger">{errors.email}</small>}
                                <div>
                                </div>
                            </div>
                        </form>
                    </Card.Body>
                    <Card.Footer>
                        <div className='text-end'>
                            <button type='button' onClick={handleSubmit} className='btn btn-primary'>Submit</button>
                        </div>
                    </Card.Footer>
                    <Toast ref={toastRef} />
                </Card>  
            </div>
            <Footer />
      </div>
    </div>
  );
}

export default ForgotPassword;
