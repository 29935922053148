import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/style.css';

const Error404Page = () => {
    return (
      <div className='error-container'>
        <img src="/img/404.png" alt="404 Error" className='error-icon' />
        <h1 className='error-heading'>Oops! Page Not Found</h1>
        <p className='error-message'>
          The page you are looking for does not exist.{' '}
          <Link to="/home" className='error-link'>
            Go back to safety
          </Link>
        </p>
      </div>
    );
};
export default Error404Page;
