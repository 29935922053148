import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';
const LOGOUT_API = `${BASE_URL}/api/user/logout`;

const Header = () => {
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');
  const sessionToken = Cookies.get('sessionToken');
  const decodedAccessToken = jwtDecode(accessToken);

  if (!accessToken) {
    navigate('/')
  } 

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${LOGOUT_API}?sessionToken=${sessionToken}`,
        null,
        {
          headers: {
            'authorization': `${accessToken}`,
          },
          withCredentials: true,
        }
      );      
  
      if (response.data.statusCode === 200) {
        Cookies.remove('accessToken');
        Cookies.remove('sessionToken');
        navigate('/');
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  return (
    <div>
      {accessToken && decodedAccessToken.role === 'admin' ? (
        <AdminHeader handleLogout={handleLogout} />
      ) : (
        <UserHeader handleLogout={handleLogout} />
      )}
    </div>
  );
};

const UserHeader = ({ handleLogout }) => {
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');
  const decodedAccessToken = jwtDecode(accessToken);

  const handleHomeClick = () => {
    navigate('/home');
  };
  const handleHistoryClick = () => {
    navigate('/history');
  };
  return (
    <Navbar className='px-2'>
      <Navbar.Brand href="/">
        <Row>
          <Col>
            <img alt="" src="/img/DA-Logo.png" className='navbar-logo'/>
          </Col>
          <Col className='p-0'>
            <div className='login-brand-text'>
              RSBSA
            </div>
            <div className='login-brand-text2' >
              CROSS<span style={{color: 'orange'}}>MATCH</span>
            </div>
        </Col>
        </Row>
      </Navbar.Brand>
      <Nav className="me-auto">
        <Nav.Item as="li" className='ms-3'>
          <Nav.Link onClick={handleHomeClick}>Home</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" className='ms-3'>
          <Nav.Link onClick={handleHistoryClick}>History</Nav.Link>
        </Nav.Item>    
      </Nav>
      <Nav className="ml-auto">
        <Nav.Item as="li" >
          <div className='welcome-text'>Welcome, {`${decodedAccessToken.userName}`}!</div>
        </Nav.Item>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <img
              src="/img/user.png"
              alt="Avatar"
              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '8px' }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ left: 'auto', right: 0 }}>
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
}

const AdminHeader = ({ handleLogout }) => {
  const navigate = useNavigate();
  const accessToken = Cookies.get('accessToken');
  const decodedAccessToken = jwtDecode(accessToken);

  const handleManageUsersClick = () => {
    navigate('/manage');
  };
  const handleHomeClick = () => {
    navigate('/home');
  };
  const handleHistoryClick = () => {
    navigate('/history');
  };

  const handleDatabaseClick = () => {
    navigate('/update_datalist');
  };
  return (
    <Navbar className='px-2'>
      <Navbar.Brand href="/">
        <Row>
          <Col>
            <img alt="" src="/img/DA-Logo.png" className='navbar-logo'/>
          </Col>
          <Col className='p-0'>
            <div className='login-brand-text'>
              RSBSA
            </div>
            <div className='login-brand-text2' >
              CROSS<span style={{color: 'orange'}}>MATCH</span>
            </div>
          </Col>
        </Row>
      </Navbar.Brand>
      <Nav className="me-auto">
        <Nav.Item as="li" className='ms-3'>
          <Nav.Link onClick={handleHomeClick}>Home</Nav.Link>
        </Nav.Item>
        <Nav.Item as="li" >
          <Nav.Link onClick={handleHistoryClick}>History</Nav.Link>
        </Nav.Item>  
      </Nav>
      <Nav className="ml-auto">
        <Nav.Item as="li" >
          <div className='welcome-text'>Welcome, {`${decodedAccessToken.userName}`}!</div>
        </Nav.Item>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <img
              src="/img/user.png"
              alt="Avatar"
              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '8px' }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ left: 'auto', right: 0 }}>
            <Dropdown.Item onClick={handleManageUsersClick}>Manage Users</Dropdown.Item>
            <Dropdown.Item onClick={handleDatabaseClick}>Update Datalist</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </Navbar>
  );
}

export default Header ;