import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ForgotPassword from "./js/Pages/ForgotPassword";
import ResetPassword from "./js/Pages/ResetPassword";
import Home from './js/Pages/Home';
import Login from './js/Pages/Login';
import ManageUsers from './js/Pages/ManageUsers';
import History from './js/Pages/History';
import UpdateDatalist from './js/Pages/UpdateDatalist';
import Error404Page from './js/Error/Error_404';
import Error500Page from './js/Error/Error_500';
import NetworkErrorPage from './js/Error/Network_Error';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />}></Route>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/manage' element={<ManageUsers />}></Route>
          <Route path='/forgot-password' element={<ForgotPassword />}></Route>
          <Route path='/reset-password' element={<ResetPassword />}></Route>
          <Route path='/history' element={<History />}></Route>
          <Route path='/update_datalist' element={<UpdateDatalist />}></Route>
          <Route path="*" element={<Error404Page />}></Route>
          <Route path="/500" element={<Error500Page />}></Route>
          <Route path="/network-error" element={<NetworkErrorPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
