import React from 'react';
import { Navbar } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Header() {
  
  return (
    <Navbar className='px-5'>
      <Navbar.Brand href="/">
        <Row>
          <Col>
            <img alt="" src="/img/DA-Logo.png" className='navbar-logo'/>
          </Col>
          <Col className='p-0'>
            <div className='login-brand-text'>
              RSBSA
            </div>
            <div className='login-brand-text2' >
              CROSS<span style={{color: 'orange'}}>MATCH</span>
            </div>
        </Col>
        </Row>
      </Navbar.Brand>
    </Navbar>
  );
}

export default Header;
