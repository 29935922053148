import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/style.css';

const Error500Page = () => {
    return (
      <div className='error-container'>
        <img src="/img/500.png" alt="500 Error" className='error-icon' />
        <h1 className='error-heading'>Internal Server Error</h1>
        <p className='error-message'>
          Whoops! Our server seems to be experiencing some technical difficulties.
          We're working hard to fix it. Meanwhile, you can{' '}
          <Link to="/home" className='error-link'>
            return to the home page
          </Link>{' '}
        or try again later.
        </p>
      </div>
    );
};
export default Error500Page;
