import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { Toast } from 'primereact/toast';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../Components/UserContext';
import Header from '../Layouts/Header';
import '../../css/App.css';
import '../../css/style.css';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:4000';

const History = () => {
  const { handleLogout, refreshToken, sessionModalShow, setSessionModalShow, isAccessTokenExpired } = useUserContext();
  const [historyDates, setHistoryDates] = useState([]);
  const [withMatchData, setWithMatchData] = useState([]);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState(10);
  const [rowsPerPageOptions] = useState([5, 10, 25, 50, 100, 500, 1000]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const accessToken = Cookies.get('accessToken');
  const decodedAccessToken = jwtDecode(accessToken);
  const navigate = useNavigate();
  const toastRef = useRef(null);
  const dataTableRef = useRef(null);
  const [visibleColumns, setVisibleColumns] = useState([
    'Uploaded_fullname',
    'Uploaded_mun_name',
    'RSBSA_sys_generated_rsbsa_no',
    'RSBSA_fullname',
    'RSBSA_mun_name',
    'percentage',
    'remarks',
  ]);

  const encodedCount = withMatchData.filter(item => item.remarks === 'ENCODED').length;
  const verificationCount = withMatchData.filter(item => item.remarks === 'FOR VERIFICATION').length;
  const notEncodedCount = withMatchData.filter(item => item.remarks === 'NOT ENCODED').length;
  
  const showToast = (severity, summary, detail) => {
    toastRef.current.show({ severity, summary, detail });
  };

  const determineRemarks = (rowData) => {
    const remarksValue = rowData.remarks;

    const setBadgeColor = () => {
      if (remarksValue === 'ENCODED') {
        return 'success';
      } else if (remarksValue === 'FOR VERIFICATION') {
        return 'warning';
      } else if (remarksValue === 'SKIP NOT ENOUGH DATA') {
        return 'info';
      } else if (remarksValue === 'NOT ENCODED') {
        return 'danger';
      }
      return 'light'; 
    };
    return (
      <Badge bg={setBadgeColor()}>{remarksValue}</Badge>
    );
  };

  const fetchHistoryDates = async (email) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/get-history-dates?email=${email}`, 
        {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `${accessToken}`,
          },
          withCredentials: true,
        });
      if (response.data.statusCode === 200) {
        const sortedDates = response.data.response.sort((a, b) => {
          const dateA = new Date(Object.values(a)[0]);
          const dateB = new Date(Object.values(b)[0]);
          return dateB - dateA;
        });
        const groupedDates = groupedMonthYear(sortedDates);
        setHistoryDates(groupedDates);
      } else {
        showToast('error', 'Error', response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.value);
  };

  const groupedMonthYear = (date) => {
    const groupedDates = [];
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
  
    date.forEach((dateObjects) => {
      const item = Object.values(dateObjects)[0];
      const dateTime = new Date(item);
      const userTimeZoneOffset = today.getTimezoneOffset();
      const localDateTime = new Date(dateTime.getTime() - userTimeZoneOffset * 60000);
      const diffDays = Math.round(Math.abs((today - localDateTime) / oneDay));
      let category;

      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      
      const isToday = (
        today.getDate() === dateTime.getDate() &&
        today.getMonth() === dateTime.getMonth() &&
        today.getFullYear() === dateTime.getFullYear()
      );

      const isYesterday = (
        yesterday.getDate() === dateTime.getDate() &&
        yesterday.getMonth() === dateTime.getMonth() &&
        yesterday.getFullYear() === dateTime.getFullYear()
      );

      const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
      }

      if (isToday) {
        category = 'Today';
      } else if (isYesterday) {
        category = 'Yesterday';
      } else if (diffDays < 7) {
        category = 'This Week';
      } else if (diffDays >= 7 && diffDays < 14) {
        category = 'A Week Ago';
      } else if (diffDays >= 14 && diffDays < 21) {
        category = '2 Weeks Ago';
      } else if (diffDays >= 21 && diffDays < 28) {
        category = '3 Weeks Ago';
      } else if (diffDays >= 29) {
        category = 'A Month Ago';
      } else if (diffDays < 365) {
        const diffMonths = Math.floor(diffDays / 30);
        category = diffMonths === 1 ? 'A Month Ago' : `${diffMonths} Months Ago`;
      } else {
        const dateTimeYear = dateTime.getFullYear();
        const isLeap = isLeapYear(dateTimeYear);
        if (isLeap && diffDays === 366) {
          category = 'A Year Ago';
        } else if (!isLeap && diffDays === 365) {
          category = 'A Year Ago';
        } else {
          const diffYears = Math.floor(diffDays / 365);
          category = diffYears === 1 ? 'A Year Ago' : `${diffYears} Years Ago`;
        }
      }
  
      const monthYear = `${dateTime.toLocaleString('default', { month: 'long' })} ${dateTime.getFullYear()}`;
      const label = `${category} - ${monthYear}`;
      let existingMonthYearGroup = groupedDates.find((group) => group.monthYear === monthYear);
  
      if (!existingMonthYearGroup) {
        existingMonthYearGroup = {
          monthYear,
          dates: [],
        };
        groupedDates.push(existingMonthYearGroup);
      }
  
      const existingCategoryGroup = existingMonthYearGroup.dates.find((group) => group.label === label);
  
      if (existingCategoryGroup) {
        existingCategoryGroup.dates.push(dateObjects);
      } else {
        existingMonthYearGroup.dates.push({
          label,
          category,
          dates: [dateObjects],
        });
      }
    });
  
    return groupedDates;
  };

  const onGlobalFilterChange = (event) => {
    setGlobalFilter(event.target.value);
  };

  const generateFilterOptions = () => {
    const allCategories = historyDates.reduce(
      (categories, group) =>
        categories.concat(group.dates.map((categoryGroup) => categoryGroup.category)),
      []
    );

    return [...new Set(allCategories)].map((category) => ({
      label: category,
      value: category,
    }));
  };

  const handleFilterChange = (event) => {
    setSelectedCategory(event.value);
  }

  const concatName = (data) => {
    return data.map((item) => {
      return {
        ...item,
        csvData: {
          ...item.csvData,
          fullname: `${item.csvData.lastname} ${item.csvData.firstname} ${item.csvData.middle_name}`,
        },
        dbData: {
          ...item.dbData,
          fullname: item.remarks !== 'NOT ENCODED' ? `${item.dbData.lastname} ${item.dbData.firstname} ${item.dbData.middle_name}` : '',
        },
      };
    });
  };  

  const prefixedData = concatName(withMatchData).map((item) => {
    const prefixedCsvData = Object.fromEntries(
      Object.entries(item.csvData).map(([key, value]) => [`Uploaded_${key}`, value])
    );
  
    const prefixedDbData = Object.fromEntries(
      Object.entries(item.dbData).map(([key, value]) => [`RSBSA_${key}`, value])
    );
  
    const additionalData = {
      percentage: item.percentage ? `${parseFloat(item.percentage).toFixed(2)}%` : '',
      remarks: item.remarks,
    };
  
    return { ...prefixedCsvData, ...prefixedDbData, ...additionalData };
  });

  const filteredData = prefixedData.filter((rowData) => {
    return Object.values(rowData).some((value) =>
      String(value).toLowerCase().includes(globalFilter.toLowerCase())
    );
  }); 

  const allKeys = Object.keys(prefixedData[0] || {});

  const multiSelectOptions = allKeys.map((columnName) => ({
    label: columnName,
    value: columnName,
  }));

  useLayoutEffect(() => {
    const fetchUserEmail = async () => {
      try {
        if (!accessToken) {
          navigate('/', { replace: true });
        } else {
          await fetchHistoryDates(decodedAccessToken.email);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserEmail();
  }, [navigate, decodedAccessToken.email]);

  const fetchHistory = async (selectedDate) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/get-history?email=${decodedAccessToken.email}&date=${selectedDate}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${accessToken}`,
          },
          withCredentials: true,
        });
      if (response.data.statusCode === 200) {
        const withMatchDataArray = response.data.response;
        setWithMatchData(withMatchDataArray);
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      showToast('error', 'Error', error.message);
    }
  };

  useEffect(() => {
    setVisibleColumns(['Uploaded_fullname', 'Uploaded_mun_name', 'RSBSA_sys_generated_rsbsa_no', 'RSBSA_fullname', 'RSBSA_mun_name', 'percentage', 'remarks']);
  }, [withMatchData]);

  const onColumnToggle = (event) => {
    const uploadedColumns = event.value.filter((column) => column.startsWith('Uploaded_'));
    const rsbsaColumns = event.value.filter((column) => column.startsWith('RSBSA_'));
    const additionalColumns = ['percentage', 'remarks'];
  
    setVisibleColumns([...uploadedColumns, ...rsbsaColumns, ...additionalColumns]);
  };

  const multiSelectHeader = (
    <div>
    <MultiSelect
      value={visibleColumns}
      options={multiSelectOptions}
      onChange={onColumnToggle}
      placeholder="Select Columns"
      display="chip"
    />
    </div>
  );

  const columns = visibleColumns.map(key => (
    <Column key={key} field={key} header={key} footer={key}
    body={(rowData) => (
      <div>
        {key === 'remarks' ? determineRemarks(rowData) : rowData[key]}
      </div>
    )}
    sortable
    />
  ));

  const exportData = () => {
    if (dataTableRef.current) {
      const csvVisibleColumnCount = visibleColumns.filter(columnName => columnName.startsWith('Uploaded_')).length;
      const uploadedColumnsReversed = [...visibleColumns].reverse();
      const lastUploadedIndex = visibleColumns.length - 1 - uploadedColumnsReversed.indexOf(uploadedColumnsReversed.find(column => column.startsWith('Uploaded_')));

      const currentDate = new Date();
      const formattedDateTime = currentDate.toISOString().replace(/:/g, '-').replace(/\..+/, '');
  
      const additionalHeader = `Uploaded Data${','.repeat(csvVisibleColumnCount + 4)}RSBSA Data`;
      const header = `${visibleColumns.slice(0, lastUploadedIndex + 1)},${''},${''},${''},${''},${visibleColumns.slice(lastUploadedIndex + 1)}`;
  
      const combinedData = [
        additionalHeader,
        header,
        ...prefixedData.map((rowData) => {
          const rowValues = visibleColumns.map((columnName) => {
            const field = rowData[columnName];
            return field !== undefined ? field : '';
          });
          rowValues.splice(lastUploadedIndex + 1, 0, '', '', '', '');
          return rowValues.join(',');
        }),
      ].join('\n');  
  
      const blob = new Blob([combinedData], { type: 'text/csv;charset=utf-8;' });
  
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `CrossMatchedData_${formattedDateTime}.csv`;
      link.click();
    }
  };

  const RowNumber = ({ rowIndex }) => (
    <div>{rowIndex + 1}</div>
  );

  return (
    <div>
      <Header handleLogout={handleLogout} />
      <div className="content">
        <div className="vh-100">
          <div className="text-start mt-3 mb-3 ">
            <label htmlFor="filterDropdown" className="me-2 ms-3">Filter by Date:</label>
            <Dropdown
              value={selectedCategory}
              options={generateFilterOptions()}
              onChange={handleFilterChange}
              placeholder="Select Date"
              className="w-auto"
            />
          </div>
          <div className="card m-2">
          <Accordion activeIndex={0}>
            {historyDates.length > 0 ? (
              historyDates
              .filter(group => {
                return !selectedCategory || group.dates.some(date => {
                  return date.label.includes(selectedCategory);
                });
              })
              .map((group, groupIndex) => (
                <AccordionTab key={groupIndex} header={group.monthYear}>
                  <div className='text-start' style={{ fontWeight: 'bold' }}>
                    {group.dates.map((categoryGroup, categoryIndex) => (
                      <div key={categoryIndex}>
                        <div>{categoryGroup.category}</div>
                        <hr />
                        <Accordion>
                          {categoryGroup.dates.map((item, index) => (
                            <AccordionTab key={index}
                            header={new Date(Object.values(item)[0]).toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })}
                            onClick={() => fetchHistory(Object.values(item)[0])} className='mb-2'>
                              {withMatchData.length > 0 && (
                                <>
                                  <div id='home-container' className='home-container mx-auto px-1 rounded bg-white'>
                                    <div className='text-start'>
                                      <Row className='g-3 mx-1'>
                                        <Col md="auto"><Card className='counter-card' title={<>Encoded <Badge bg="success">Encoded</Badge></>}> {encodedCount} </Card></Col>
                                        <Col md="auto"><Card className='counter-card' title={<>For Verification <Badge bg="warning">For Verification</Badge></>}> {verificationCount} </Card></Col>
                                        <Col md="auto"><Card className='counter-card' title={<>Not Encoded <Badge bg="danger">Not Encoded</Badge></>}> {notEncodedCount} </Card></Col>
                                      </Row>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                      <div className='text-start ms-3 mt-3'>
                                        <Button
                                          tooltip="Only displayed columns will be exported. Toggle all the columns you want to export."
                                          icon="pi pi-file-o"
                                          label="Export Data"
                                          onClick={() => exportData()}
                                          size="small"
                                          raised
                                        />
                                      </div>
                                      <div className="text-end mt-3">
                                        <input
                                          type="text"
                                          value={globalFilter}
                                          onChange={onGlobalFilterChange}
                                          placeholder="Search..."
                                          style={{
                                            padding: '12px 40px 12px 15px',
                                            fontSize: '16px',
                                            borderRadius: '5px',
                                            border: '1px solid #ccc',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                            boxSizing: 'border-box',
                                            outline: 'none',
                                            transition: 'border-color 0.2s',
                                          }}
                                          onFocus={(e) => (e.target.style.borderColor = '#89CFF0')}
                                          onBlur={(e) => (e.target.style.borderColor = '#ccc')}
                                        />
                                        <span style={{ position: 'relative', left: '-40px', bottom: '3px' }}>
                                          <FaSearch color="#4682B4" size={18} />
                                        </span>
                                      </div>
                                    </div>
                                    <div className='mt-3'>
                                      <DataTable 
                                        ref={dataTableRef}
                                        value={filteredData}
                                        header={multiSelectHeader}
                                        paginator
                                        rows={rows}
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        className="p-datatable-striped"
                                        size={'small'}
                                        removableSort
                                        resizableColumns
                                        reorderableColumns
                                        columnResizeMode="expand">
                                        <Column
                                          body={(rowData, column) => <RowNumber rowIndex={column.rowIndex} />}
                                          header="#" footer='#'
                                        />
                                        {columns}
                                      </DataTable>
                                    </div>
                                  </div>
                                </>
                              )}
                            </AccordionTab>
                          ))}
                        </Accordion>
                      </div>
                    ))}
                  </div>
                </AccordionTab>
              ))
            ) : (
              <AccordionTab header="No history yet...">
                <div>No history yet...</div>
              </AccordionTab>
            )}
          </Accordion>
            <Toast ref={toastRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
