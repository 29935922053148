import * as yup from 'yup';

const passwordRequirement = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s_]).{8,}$/;

const ValidationSchema = yup.object().shape({
    firstName: yup.string().required("Firstname required"),
    lastName: yup.string().required("Lastname required"),
    userName: yup.string().required("Username required"),
    email: yup.string().email("Please enter a valid email").required("Email required"),
    password: yup
      .string()
      .min(8)
      .matches(passwordRequirement, { message: "Password must contain a lowercase and uppercase character, a number and a special character" })
      .required("Password required"),
    confirmPassword: yup
      .string()
      .required('Please confirm your password')
      .test('passwords-match', 'Passwords do not match', function (value) { return value === this.parent.password }),
    role: yup.string().required("Role required"),
    office_code: yup.string().required("Office required"),
});

export default ValidationSchema;