import { MDBFooter } from 'mdb-react-ui-kit';
import React from 'react';

export default function Footer() {
  return (
    <MDBFooter>
      <div className='text-center p-3'>
        <p>© 2023 RSBSA</p>
      </div>
    </MDBFooter>
  );
}