import * as yup from 'yup';

const passwordRequirement = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s_]).{8,16}$/;

const ValidationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8)
      .matches(passwordRequirement, { message: "Password must contain a lowercase and uppercase character, a number and a special character" })
      .required("Password required"),
    confirmPassword: yup
      .string()
      .required('Please confirm your password')
      .test('passwords-match', 'Passwords do not match', function (value) { return value === this.parent.password }),
});

export default ValidationSchema;